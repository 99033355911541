@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "TomatoGrotesk";
  src: url("../assets/fonts/TomatoGrotesk-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "TomatoGrotesk";
  src: url("../assets/fonts/TomatoGrotesk-Semibold.woff2") format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "TomatoGrotesk";
  src: url("../assets/fonts/TomatoGrotesk-Bold.woff2") format("woff2");
  font-weight: 700;
}

@font-face {
  font-family: "TomatoGrotesk";
  src: url("../assets/fonts/TomatoGrotesk-Black.woff2") format("woff2");
  font-weight: 800;
}

html,
body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
li,
div {
  font-family: "TomatoGrotesk", sans-serif;
}


html, body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: spin infinite 20s linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
